<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!repair">
      <span class="loader"></span>
    </span>

    <div class="pl-3 pl-md-4" v-if="repair">
      <vehicle-ref-standardized
        :vehicle="repair.vehicle"
        @vehicleChanged="onViewRepair"
        gallery-selector
      >
        <template #second-title>
          <h4>{{ repair.code }}</h4>
        </template>
      </vehicle-ref-standardized>
    </div>
    <div class="elite-tabs-wrapper" v-if="repair">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper tabs-wrapper-nav"
        tabNavClasses="nav elite-tabs"
        :value="getActiveTab()"
        @onTabChanged="tabChanged"
      >
        <tab-pane title="global" id="1" :active="false">
          <span slot="title">
            <i class="fa-regular fa-list"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <div class="tab-pane-inner"></div>
          <repair-view-global
            :repair="repair"
            :view-header="false"
            @onCancelRepair="onCancelRepair"
            @onEditRepair="onEditRepair"
            @onViewRepair="onViewRepair"
          />
        </tab-pane>

        <tab-pane title="images" id="2" :active="false">
          <span slot="title">
            <i class="fa-regular fa-image"></i>
            {{ $t("COMMON.PICTURES") }}
          </span>
          <repair-view-images :repair="repair" />
        </tab-pane>

        <tab-pane
          title="inspection"
          id="3"
          :active="false"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_INSPECTIONS) &&
            !!repair.inspection
          "
        >
          <span slot="title">
            <i class="fa-regular fa-clipboard-check"></i>
            {{ $t("COMMON.INSPECTION") }}
          </span>
          <div class="tab-pane-inner"></div>
          <repair-view-inspection
            :repair="repair"
            @onViewRepair="onViewRepair"
          />
        </tab-pane>

        <tab-pane
          title="estimation"
          id="4"
          :active="true"
          v-if="
            !!repair.estimation &&
            !repair.estimation.by_pass &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ESTIMATIONS)
          "
        >
          <span class="title" slot="title">
            <i class="far fa-usd-circle"></i>
            {{ $t("COMMON.ESTIMATION") }}
          </span>
          <div class="tab-pane-inner">
            <repair-view-estimate
              :loading="loading"
              :repair="repair"
              @onViewRepair="onViewRepair"
            />
          </div>
        </tab-pane>

        <tab-pane
          :active="false"
          id="5"
          title="repair"
          v-if="repair.status != REPAIR_STATUS_DRAFT"
        >
          <span class="title" slot="title">
            <i class="fa-regular fa-wrench"></i>
            {{ $t("COMMON.REPAIR") }}
          </span>
          <div class="tab-pane-inner">
            <repair-view-repair
              :repairData="repair"
              :loading="isLoading"
              :hourlyRateValue="hourlyRate"
              @repairUpdated="repairUpdated"
              @onCloseRepairModal="onCloseRepairModal"
              @onCancelRepair="onCancelRepair"
              @onStartRepair="onStartRepair"
              @onEndRepair="onEndRepair"
              @onUpdateRepair="updateRepair"
              @onTakeOverRepair="onTakeOverRepair"
              :key="repairViewRepairKey"
            />
          </div>
        </tab-pane>

        <tab-pane
          title="vehicle"
          id="6"
          :active="false"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
            !!repair.vehicle
          "
        >
          <span class="title" slot="title">
            <i class="fa fa-car"></i>
            {{ $t("COMMON.VEHICLE") }}
          </span>
          <div class="tab-pane-inner">
            <repair-view-vehicle
              :repair="repair"
              @onUpdateRepair="updateRepair"
              @onViewRepair="onViewRepair"
            />
          </div>
        </tab-pane>

        <!-- <tab-pane
          title="client"
          id="7"
          :active="false"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS)"
        >
          <span class="title" slot="title">
            <i class="fa fa-user"></i>
            {{ $t("COMMON.CUSTOMER") }}
          </span>
          <repair-view-customer
            :repair="repair"
            @onUpdateRepair="updateRepair"
          />
        </tab-pane> -->

        <!-- <tab-pane
          title="files"
          id="8"
          :active="false"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span class="title" slot="title">
            <i class="fa fa-file-alt"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="repair" />
          </div>
        </tab-pane> -->

        <!-- <tab-pane
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
          id="999"
          title="logs"
        >
          <span slot="title">
            <i class="fa fa-history"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <repair-view-logs :repair="repair" />
        </tab-pane> -->
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { REPAIR_STATUS_DRAFT } from "@/constants/repairs";
import { Tabs, TabPane } from "@/components";
// import ListFileComponent from "@/components/File/ListFileComponent.vue";
import RepairViewRepair from "../partials/RepairViewRepair.vue";
// import RepairViewLogs from "../partials/RepairViewLogs.vue";
import RepairViewGlobal from "../partials/RepairViewGlobal.vue";
import RepairViewVehicle from "../partials/RepairViewVehicle.vue";
// import RepairViewCustomer from "../partials/RepairViewCustomer.vue";
import RepairViewInspection from "../partials/RepairViewInspection.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import swal from "sweetalert2";
import repairPermissionsMixin from "@/mixins/repair-permissions-mixin";
import RepairViewImages from "../partials/RepairViewImages.vue";
import RepairViewEstimate from "../partials/RepairViewEstimate.vue";
import { mapGetters } from "vuex";
import hasTabs from "@/mixins/has-tabs";
import VehicleRefStandardized from "@/components/VehicleRefStandardized.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    RepairViewRepair,
    // RepairViewLogs,
    RepairViewVehicle,
    RepairViewGlobal,
    // RepairViewCustomer,
    RepairViewInspection,
    RepairViewImages,
    RepairViewEstimate,
    // ListFileComponent,
    VehicleRefStandardized,
  },

  mixins: [requestErrorMixin, repairPermissionsMixin, hasTabs],

  props: {
    repairId: {
      type: [String, Number],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const isLoading = this.loading ?? false;
    return {
      repair: null,
      REPAIR_STATUS_DRAFT,
      isLoading,
      hourlyRate: 0,
      repairViewRepairKey: 1,
    };
  },

  computed: {
    // canViewRepair() {
    //   return this.repair?.status != REPAIR_STATUS_DRAFT;
    // },
    ...mapGetters({
      // organizationConfig: "organizationOptions/organizationConfig",
    }),
  },

  async created() {
    await this.get();

    // if (this.repair?.organization) {
    //   await this.$store.dispatch(
    //     "organizationOptions/getOrganizationConfig",
    //     this.repair.organization.id
    //   );
    //   // this.hourlyRate =
    //   //   this.organizationConfig.DEFAULT_ESTIMATION_ITEM_HOURLY_RATE ?? 0;
    //   this.repairViewRepairKey = this.repairViewRepairKey * 400;
    // }
  },

  methods: {
    async get() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("repairs/get", this.repairId);
        this.repair = this.$store.getters["repairs/repair"];
        if (this.canViewRepair(this.repair)) {
          this.$emit("repairLoaded", this.repair);
        } else {
          this.$emit("onEditRepair", this.repair);
        }
        swal.close();
      } catch (error) {
        swal.close();
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      } finally {
        this.isLoading = false;
      }
    },

    async repairUpdated() {
      await this.get();
      this.$emit("repairUpdated", true);
    },

    onCloseRepairModal() {
      this.$emit("onCloseRepairModal", true);
    },

    onEditRepair() {
      this.$emit("onEditRepair", this.repair);
    },

    async onCancelRepair() {
      await this.get();

      this.$emit("onCancelRepair", this.repair);
    },

    async onStartRepair() {
      await this.startRepair();

      await this.get();

      // this.$emit("onStartRepair", this.repair);
    },

    async startRepair() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("repairs/start", {
          repairId: this.repair.id,
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_STARTED"),
        });
      } catch (error) {
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.isLoading = false;
      }
    },

    async onEndRepair() {
      await this.get();

      this.$emit("onEndRepair", this.repair);
    },

    async onStartTimesRepair() {
      await this.startTimesRepair();

      await this.get();
      // this.$emit("onStartTimesRepair", this.repair);
    },

    async startTimesRepair() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("repairs/startTimes", this.repair.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_STARTED_TIMES"),
        });
      } catch (error) {
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.isLoading = false;
      }
    },

    async onEndTimesRepair() {
      await this.get();

      this.$emit("onEndTimesRepair", this.repair);
    },

    async onStartApproveRepair() {
      await this.startApproveRepair();

      await this.get();
      // this.$emit("onStartApproveRepair", this.repair);
    },

    async startApproveRepair() {
      this.isLoading = true;

      try {
        await this.$store.dispatch("repairs/startApprove", this.repair.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_STARTED_APPROVE"),
        });
      } catch (error) {
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.isLoading = false;
      }
    },

    async onEndApproveRepair() {
      await this.get();

      this.$emit("onEndApproveRepair", this.repair);
    },

    async updateRepair(repairData) {
      this.isLoading = true;
      swal.showLoading();
      try {
        await this.$store.dispatch("repairs/update", repairData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_UPDATED"),
        });

        await this.get();

        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.isLoading = false;
      }
    },

    onTakeOverRepair(data) {
      this.$emit("onTakeOverRepair", this.repair, data);
    },

    onTakeOverTimesRepair(data) {
      this.$emit("onTakeOverTimesRepair", this.repair, data);
    },

    getActiveTab() {
      if (this.canStartRepair(this.repair) || this.canEndRepair(this.repair)) {
        return "repair";
      }
      return this.currentTab;
    },

    onViewRepair(repair = null) {
      if (!repair) {
        repair = this.repair;
      }

      this.$emit("onViewRepair", repair, true);
    },
  },

  watch: {
    loading(value) {
      this.isLoading = value;
    },
  },
};
</script>
