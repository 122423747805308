<template>
  <div class="add-inspection-steps">
    <div class="add-inspection-steps-header">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step
          :title="$t('COMMON.VEHICLE')"
          @click.native="switchToStep(VEHICLE_STEP)"
          class="cursor-pointer"
        >
        </el-step>
        <el-step
          :title="$t('COMMON.CUSTOMER')"
          @click.native="switchToStep(CUSTOMER_STEP)"
          :class="{ 'cursor-pointer': canPassVehicleStep }"
        ></el-step>
        <el-step
          title="Validation"
          v-if="this.$currentUserCan(this.$permissions.PERM_VALIDATE_REPAIRS)"
          @click.native="switchToStep(VALIDATION_STEP)"
          :class="{ 'cursor-pointer': canPassCustomerStep }"
        ></el-step>
        <el-step
          title="Assignation"
          v-if="canAssignRepair(repair)"
          @click.native="switchToStep(ASSIGNATION_STEP)"
          :class="{ 'cursor-pointer': canPassValidateStep }"
        ></el-step>
      </el-steps>
    </div>
    <div class="add-inspection-steps-content">
      <div v-if="active === VEHICLE_STEP" class="steps-content-1">
        <div class="step-content-body">
          <repair-form-vehicle-step
            :repair-data.sync="repair"
            :form-errors="formErrors"
            :disabled="!canEditRepair(repair)"
            @onViewRepair="onViewRepair"
            @onCloseRepairModal="onCloseRepairModal"
          />
        </div>
        <div class="steps-content-footer">
          <Button
            v-if="canCancelRepair(repair)"
            class="cancel"
            @click="showCancelModal = true"
          >
            {{ $t("REPAIRS.CANCEL_REPAIR") }}
          </Button>
          <Button
            class="next"
            @click="passVehicleStep"
            :disabled="!canPassVehicleStep"
            :class="{ 'opacity-5 cursor-not-allowed': !canPassVehicleStep }"
          >
            <i class="fas fa-spinner fa-spin mr-1" v-if="isLoading"></i>
            {{ $t("COMMON.NEXT") }}
            <i class="fal fa-long-arrow-right"></i>
          </Button>
          <Button
            class="prev"
            @click="onViewRepair()"
            v-if="canViewRepair(repair)"
          >
            {{ $t("REPAIRS.SHOW_REPAIR") }}
          </Button>
        </div>
      </div>

      <div v-if="active === CUSTOMER_STEP" class="steps-content-2">
        <div class="step-content-body">
          <customer-step-form-component
            :object-data.sync="repair"
            :form-errors="formErrors"
            :disabled="!canEditRepair(repair)"
          />
        </div>
        <div class="steps-content-footer">
          <Button
            v-if="canCancelRepair(repair)"
            class="cancel"
            @click="showCancelModal = true"
            >{{ $t("REPAIRS.CANCEL_REPAIR") }}</Button
          >
          <Button class="prev" @click="prev">
            <i class="fal fa-long-arrow-left"></i>
            {{ $t("COMMON.PREVIOUS") }}
          </Button>
          <Button
            class="next"
            @click="passCustomerStep"
            :disabled="!canPassCustomerStep"
            :class="{ 'opacity-5 cursor-not-allowed': !canPassCustomerStep }"
          >
            <i class="fas fa-spinner fa-spin mr-1" v-if="isLoading"></i>
            {{ $t("COMMON.NEXT") }}
            <i class="fal fa-long-arrow-right"></i>
          </Button>
          <Button
            class="prev"
            @click="onViewRepair()"
            v-if="canViewRepair(repair)"
          >
            {{ $t("REPAIRS.SHOW_REPAIR") }}
          </Button>
        </div>
      </div>

      <div v-if="active === VALIDATION_STEP" class="steps-content-3">
        <div class="step-content-body">
          <repair-form-validation-step :repair-data.sync="repair" />
        </div>
        <div class="steps-content-footer">
          <Button
            v-if="canCancelRepair(repair)"
            class="cancel"
            @click="showCancelModal = true"
          >
            {{ $t("REPAIRS.CANCEL_REPAIR") }}
          </Button>
          <Button class="prev" @click="prev">
            <i class="fal fa-long-arrow-left"></i>
            {{ $t("COMMON.PREVIOUS") }}
          </Button>
          <Button
            class="next"
            @click="next"
            v-if="canPassValidateStep && canAssignRepair(repair)"
          >
            {{ $t("COMMON.NEXT") }}
            <i class="fal fa-long-arrow-right"></i>
          </Button>

          <Button
            class="next"
            @click="close"
            v-if="canPassValidateStep && !canAssignRepair(repair)"
          >
            {{ $t("COMMON.CLOSE") }}
          </Button>

          <Button
            class="validate"
            @click="ValidateRepair"
            v-if="!canPassValidateStep"
          >
            <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
            {{ $t("COMMON.VALIDATE") }}
          </Button>
          <Button
            class="prev"
            @click="onViewRepair(repair)"
            v-if="canViewRepair(repair)"
          >
            {{ $t("REPAIRS.SHOW_REPAIR") }}
          </Button>
        </div>
      </div>

      <div v-if="active === ASSIGNATION_STEP" class="steps-content-4">
        <div class="step-content-body">
          <repair-form-assignation-step
            :repair-data="repair"
            :role.sync="role"
            :users.sync="users"
            :disabled="!isAssignable(repair)"
            @submitAssignation="assignRepair"
          />
        </div>
        <div class="steps-content-footer">
          <Button
            v-if="canCancelRepair"
            class="cancel"
            @click="showCancelModal = true"
          >
            {{ $t("REPAIRS.CANCEL_REPAIR") }}
          </Button>
          <Button class="prev" @click="prev">
            <i class="fal fa-long-arrow-left"></i>
            {{ $t("COMMON.PREVIOUS") }}
          </Button>
          <Button
            :disabled="!canProceedAssignment"
            @click="assignRepair"
            class="next"
            :class="{ 'opacity-5 cursor-not-allowed': !canProceedAssignment }"
          >
            {{ $t("COMMON.END") }}
          </Button>
          <Button
            class="prev"
            @click="onViewRepair(repair)"
            v-if="canViewRepair"
          >
            {{ $t("REPAIRS.SHOW_REPAIR") }}
          </Button>
        </div>
      </div>
    </div>

    <inspection-confirmation-modal
      :confirm-action="cancelRepair"
      :confirm-button-text="$t('COMMON.YES_CANCEL')"
      :isLoading="isLoading"
      :message="$t('REPAIRS.CANCEL_THIS_REPAIR')"
      :open.sync="showCancelModal"
      modal-classes="modal-secondary cancel"
    />

    <inspection-confirmation-modal
      :confirm-action="ValidateRepair"
      :confirm-button-text="$t('COMMON.YES_VALIDATE')"
      :isLoading="isLoading"
      :message="$t('REPAIRS.VALIDATE_THIS_REPAIR')"
      :open.sync="showValidateModal"
      modal-classes="modal-secondary validate"
    />

    <inspection-confirmation-modal
      :confirm-action="assignRepair"
      :confirm-button-text="$t('COMMON.YES_ASSIGN')"
      :isLoading="isLoading"
      :message="$t('REPAIRS.ASSIGN_THIS_REPAIR')"
      :open.sync="showAssignModal"
      modal-classes="modal-secondary validate"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeaveMixin from "@/mixins/alert-leave-mixin";
import { Checkbox, CheckboxGroup, Radio, Step, Steps } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import swal from "sweetalert2";
import RepairFormVehicleStep from "./RepairFormVehicleStep.vue";
import RepairFormValidationStep from "./RepairFormValidationStep.vue";
import RepairFormAssignationStep from "./RepairFormAssignationStep.vue";
import repairPermissionsMixin from "@/mixins/repair-permissions-mixin";
import {
  CUSTOMER_TYPE_COMPANY,
  CUSTOMER_TYPE_INDIVIDUAL,
} from "@/constants/customers";
import CustomerStepFormComponent from "@/components/CustomerStepFormComponent.vue";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import { REPAIR_STATUS_DRAFT } from "@/constants/repairs";
import {
  REPAIR_STATUS_ASSIGNED,
  REPAIR_STATUS_PENDING,
} from "@/constants/repairs";

export default {
  layout: "DashboardLayout",

  name: "repair-form",

  components: {
    [Steps.name]: Steps,
    [Step.name]: Step,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Radio.name]: Radio,
    RepairFormVehicleStep,
    RepairFormValidationStep,
    RepairFormAssignationStep,
    CustomerStepFormComponent,
    InspectionConfirmationModal,
  },

  mixins: [alertLeaveMixin, requestErrorMixin, repairPermissionsMixin],

  props: ["repairData", "loading"],

  data() {
    const repair = cloneDeep(this.repairData);
    const repairBackup = cloneDeep(this.repairData);
    const VEHICLE_STEP = 0;
    const CUSTOMER_STEP = 1;
    const VALIDATION_STEP = 2;
    const ASSIGNATION_STEP = 3;
    const isLoading = this.loading ?? false;
    return {
      VEHICLE_STEP,
      CUSTOMER_STEP,
      ASSIGNATION_STEP,
      VALIDATION_STEP,
      repair,
      formErrors: null,
      isLoading,
      active: 0,
      showCancelModal: false,
      showValidateModal: false,
      repairBackup,
      showAssignModal: false,
      users: [],
      role: "-1",
    };
  },

  created() {
    this.setActiveStep();
    this.fillAssignationVariables(this.repairData);
  },

  computed: {
    canPassVehicleStep() {
      return (
        !this.loading && this.$idExist(this.repair.organization?.id)
        // this.$idExist(this.repair.vehicle?.id)
      );
    },

    canPassCustomerStep() {
      return (
        this.canPassVehicleStep &&
        (this.$idExist(this.repair.customer?.id) ||
          this.$idExist(this.repair.approver?.id) ||
          !!this.repair.customer?.organization)
      );
    },

    canPassValidateStep() {
      return (
        this.canPassCustomerStep &&
        this.repair.status &&
        this.repair.status != REPAIR_STATUS_DRAFT
      );
    },

    activeStep() {
      let active;
      if (
        this.canPassValidateStep &&
        this.canAssignRepair(this.repair) &&
        this.repair.status
      ) {
        active = this.ASSIGNATION_STEP;
      } else if (this.canPassCustomerStep && this.repair.status) {
        active = this.VALIDATION_STEP;
      } else if (
        this.canPassVehicleStep &&
        this.$idExist(this.repair.vehicle?.id)
      ) {
        active = this.CUSTOMER_STEP;
      } else {
        active = this.VEHICLE_STEP;
      }
      return active;
    },

    isRoleAssignment() {
      return this.role != "-1";
    },

    canProceedAssignment() {
      return (
        [REPAIR_STATUS_ASSIGNED, REPAIR_STATUS_PENDING].includes(
          this.repair.status
        ) &&
        this.canAssignRepair(this.repair) &&
        ((this.role == "-1" && this.users.length > 0) || this.role != "-1")
      );
    },
  },

  methods: {
    prev() {
      this.active = Math.max(this.active - 1, this.VEHICLE_STEP);
    },

    next() {
      const active = Math.min(this.active + 1, this.ASSIGNATION_STEP);

      if (active == this.ASSIGNATION_STEP && !this.canAssignRepair(this.repair))
        return;

      this.active = active;
    },

    close() {
      this.$emit("onCloseRepairModal");
    },

    switchToStep(step) {
      if (step == this.ASSIGNATION_STEP && !this.canPassValidateStep) {
        return;
      }

      if (step == this.VALIDATION_STEP && !this.canPassCustomerStep) {
        return;
      }

      if (
        step == this.CUSTOMER_STEP &&
        (!this.canPassVehicleStep ||
          !this.$idExist(this.repairBackup.vehicle?.id))
      ) {
        return;
      }

      this.active = step;
    },

    setActiveStep() {
      this.active = this.activeStep;
    },

    async passVehicleStep() {
      if (!this.canEditRepair(this.repair)) {
        this.next();
        return;
      }

      this.isLoading = true;
      swal.showLoading();

      try {
        await this.handleVehicleFormSubmit(this.repair.vehicle);

        const repairData = cloneDeep(this.repair);

        if (!this.$idExist(repairData.request?.id)) {
          delete repairData.request;
        }

        if (!this.$idExist(repairData.customer?.id)) {
          delete repairData.customer;
        }

        if (!this.$idExist(repairData.inspection?.id)) {
          delete repairData.inspection;
        }

        if (!this.$idExist(repairData.estimation?.id)) {
          delete repairData.estimation;
        }

        if (!this.$idExist(repairData.approver?.id)) {
          delete repairData.approver;
        }

        await this.submitRepairForm(repairData);

        const repair = await this.$store.getters["repairs/repair"];

        if (this.$idExist(repairData?.id)) {
          this.repair = { ...repairData, ...repair };
          this.$emit("formChanged");
          this.next();
        } else {
          this.$emit("onEditRepair", repair);
        }
      } catch (error) {
        console.log({ error });
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response?.data?.errors;
      } finally {
        this.isLoading = false;
        swal.close();
      }
    },

    async passCustomerStep() {
      if (!this.canEditRepair(this.repair)) {
        this.next();
        return;
      }

      this.isLoading = true;
      swal.showLoading();
      try {
        if (!this.$idExist(this.repair.approver?.id)) {
          if (!!this.repair.customer?.organization) {
            await this.handleCustomerFormSubmit(this.repair.customer);
          }
          this.repair.approver = null;
        } else {
          this.repair.customer = null;
        }

        const repairData = cloneDeep(this.repair);

        if (!this.$idExist(repairData.request?.id)) {
          delete repairData.request;
        }

        if (!this.$idExist(repairData.request?.id)) {
          delete repairData.request;
        }

        if (!this.$idExist(repairData.inspection?.id)) {
          delete repairData.inspection;
        }

        if (!this.$idExist(repairData.estimation?.id)) {
          delete repairData.estimation;
        }

        await this.submitRepairForm(repairData);

        const repair = await this.$store.getters["repairs/repair"];

        this.repair = { ...repairData, ...repair };

        this.$emit("formChanged");

        this.next();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response?.data?.errors;
      } finally {
        this.isLoading = false;
        swal.close();
      }
    },

    async ValidateRepair() {
      this.isLoading = true;
      swal.showLoading();
      try {
        if (!this.$idExist(this.repair.customer?.id)) {
          this.repair.customer = null;
        }

        if (!this.$idExist(this.repair.approver?.id)) {
          this.repair.approver = null;
        }

        await this.submitRepairForm(this.repair);

        await this.$store.dispatch("repairs/validate", {
          repairId: this.repair.id,
          data: {},
        });

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_VALIDATED"),
        });

        const repair = await this.$store.getters["repairs/repair"];

        this.repair = { ...this.repair, ...repair };

        this.$emit("formChanged");

        if (!this.canAssignRepair(this.repair)) {
          // this.close();
          this.$emit("onViewRepair");
        } else {
          this.next();
        }

        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error, false);

        await this.getRepair(this.repair?.id);

        this.formErrors = this.refactorError(error.response?.data?.errors);

        await this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.setActiveStep();
      } finally {
        this.isLoading = false;
        this.showValidateModal = false;
      }
    },

    async cancelRepair() {
      swal.showLoading();

      try {
        await this.$store.dispatch("repairs/cancel", this.repair.id);

        const repair = await this.$store.getters["repairs/repair"];

        this.repair = { ...this.repair, ...repair };

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_CANCELED"),
        });

        this.$emit("onViewRepair");
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.showCancelModal = false;
      }
    },

    async assignRepair() {
      if (!this.canProceedAssignment) {
        return;
      }

      this.isLoading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("repairs/assign", {
          repairId: this.repair.id,
          data: this.isRoleAssignment
            ? { roles: [this.role] }
            : { users: this.users },
        });

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_ASSIGNED"),
        });

        this.$emit("onViewRepair");
      } catch (error) {
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.isLoading = false;
        this.showAssignModal = false;
      }
    },

    async submitRepairForm(repairData) {
      if (!repairData.customer) {
        repairData.customer = cloneDeep(repairData.vehicle.customer);
      }

      if (!this.$idExist(repairData.approver?.id)) {
        repairData.approver = null;
      }

      if (this.$idExist(repairData?.id)) {
        await this.$store.dispatch("repairs/update", repairData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_UPDATED"),
        });
      } else {
        delete repairData.id;
        await this.$store.dispatch("repairs/add", repairData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REPAIRS.REPAIR_ADDED"),
        });
      }
    },

    async handleVehicleFormSubmit(vehicleData) {
      vehicleData = { ...this.repair.vehicle, ...vehicleData };
      let vehicle = null;

      if (!this.$idExist(vehicleData.customer?.id)) {
        delete vehicleData.customer;
      }
      if (!this.$idExist(vehicleData.mecanic?.id)) {
        delete vehicleData.mecanic;
      }

      if (this.$idExist(vehicleData?.id)) {
        delete vehicleData.organization;
        await this.$store.dispatch("vehicles/update", vehicleData);
        vehicle = await this.$store.getters["vehicles/vehicle"];
      } else {
        delete vehicleData.id;
        await this.$store.dispatch("vehicles/add", vehicleData);
        vehicle = await this.$store.getters["vehicles/vehicle"];
      }

      this.repair.vehicle = { ...this.repair.vehicle, ...vehicle };
      this.repair.organization = {
        ...this.repair.organization,
        ...vehicle.organization,
      };

      if (vehicleData.allowedLocations.length) {
        this.repair.allowedLocations = cloneDeep(vehicleData.allowedLocations);
      }

      this.repair.gallery = vehicle?.gallery;
      this.repair.odometer = vehicle?.odometer;
      this.repair.odometer_unit = vehicle?.odometer_unit;
      this.repair.registration_number = vehicle?.registration_number;
      this.repair.color = vehicle?.color;
    },

    async handleCustomerFormSubmit(customerData) {
      customerData = { ...this.repair.customer, ...customerData };

      if (customerData.customer_type === CUSTOMER_TYPE_INDIVIDUAL) {
        customerData.company_name = "N/A";
        customerData.billing_company_name = "N/A";
      } else if (customerData.customer_type === CUSTOMER_TYPE_COMPANY) {
        customerData.firstname = "N/A";
        customerData.lastname = "N/A";
        customerData.billing_firstname = "N/A";
        customerData.billing_lastname = "N/A";
      }

      let customer = null;

      if (this.$idExist(customerData?.id)) {
        if (this.$currentUserCan(this.$permissions.PERM_EDIT_CUSTOMERS)) {
          await this.$store.dispatch("customers/update", customerData);

          customer = await this.$store.getters["customers/customer"];

          this.repair.customer = {
            ...this.repair.customer,
            ...customer,
          };
        }
      } else {
        if (this.$currentUserCan(this.$permissions.PERM_CREATE_CUSTOMERS)) {
          delete customerData.id;
          await this.$store.dispatch("customers/add", customerData);

          customer = await this.$store.getters["customers/customer"];

          this.repair.customer = {
            ...this.repair.customer,
            ...customer,
          };
        }
      }
    },

    async getRepair(repairId) {
      try {
        await this.$store.dispatch("repairs/get", repairId);
        const repair = this.$store.getters["repairs/repair"];
        this.repair = { ...this.repair, ...repair };
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    refactorError(errors) {
      return errors.map((error) => {
        let err = { ...error };
        if (error?.source?.pointer === "/data") {
          if (
            error.detail.includes("customer") ||
            error.detail.includes("client")
          ) {
            err = {
              ...err,
              source: {
                pointer: "/data/relationships/customer/data/id",
              },
            };
          }
          if (
            error.detail.includes("vehicle") ||
            error.detail.includes("véhicule")
          ) {
            err = {
              ...err,
              source: {
                pointer: "/data/relationships/vehicle/data/id",
              },
            };
          }
        }
      });
    },

    fillAssignationVariables(repair) {
      this.role = repair?.assignedToRoles?.length
        ? repair.assignedToRoles[0].id
        : "-1";
      this.users = repair?.assignedToUsers?.map((user) => user.id) ?? [];
    },

    onViewRepair(repair = null) {
      repair = repair ?? this.repair;
      this.$emit("onViewRepair", repair);
    },

    onCloseRepairModal() {
      this.$emit("onCloseRepairModal");
    },
  },

  watch: {
    formErrors(errors) {
      this.formErrorsValue = cloneDeep(errors);
    },
    repairData: {
      handler: function () {
        this.repair = { ...this.repairData };
        this.repairBackup = { ...this.repairData };
        this.fillAssignationVariables(this.repairData);
      },
      deep: true,
    },
    loading(value) {
      this.isLoading = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
