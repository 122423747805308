<template>
  <estimate-view-global
    :estimate="repair.estimation"
    :view-header="false"
    class-name=""
    :show-code="false"
  >
    <template #buttons>
      <button class="edit" @click="showEstimation" v-if="canShowEstimation">
        <span> {{ $t("INSPECTIONS.SHOW_ESTIMATION") }} </span>
        <!-- <i class="fa-light fa-eye"></i> -->
      </button>
    </template>
  </estimate-view-global>
</template>

<script>
import RepairRefStandardized from "@/components/RepairRefStandardized.vue";
import EstimateViewGlobal from "../../EstimateManagement/partials/EstimateViewGlobal.vue";

export default {
  name: "repair-view-estimate",

  components: { EstimateViewGlobal, RepairRefStandardized },

  props: ["repair"],

  data() {
    return {};
  },

  computed: {
    canShowEstimation: function () {
      return (
        !!this.repair.estimation &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_MODULE_ESTIMATIONS) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_ESTIMATIONS)
      );
    },
    title: function () {
      return `${this.repair.code} - ${this.$t("COMMON.ESTIMATION")}`;
    },
  },

  created() {},

  methods: {
    showEstimation() {
      this.$router.push(this.$objectViewRoute(this.repair.estimation));
    },
  },

  mounted() {},

  watch: {},
};
</script>
