<template>
  <div class="elite-tabs-wrapper-content">
    <inspection-view-global
      :inspection="repair.inspection"
      :view-header="false"
      class-name=""
      :show-code="false"
    >
      <template #buttons>
        <button class="edit" @click="showInspection">
          <span> {{ $t("INSPECTIONS.SHOW_INSPECTION") }} </span>
        </button>
      </template>
    </inspection-view-global>
  </div>
</template>

<script>
import RepairRefStandardized from "@/components/RepairRefStandardized.vue";
import InspectionViewGlobal from "../../InspectionManagement/partials/InspectionViewGlobal.vue";

export default {
  name: "repair-view-global",

  components: { InspectionViewGlobal, RepairRefStandardized },

  props: ["repair"],

  data() {
    return {};
  },

  computed: {
    title: function () {
      return `${this.repair.code} - ${this.$t("COMMON.INSPECTION")}`;
    },
  },

  created() {},

  methods: {
    showInspection() {
      this.$router.push(this.$objectViewRoute(this.repair.inspection));
    },
  },

  mounted() {},

  watch: {},
};
</script>
